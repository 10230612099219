import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";

interface Props {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  isDestructive?: boolean;
  onConfirm: () => Promise<void>;
}

export const confirmationDialog = async (props: Props): Promise<boolean> => {
  try {
    const result = await NiceModal.show(ConfirmationDialog, props);
    return result === true;
  } catch {
    return false;
  }
};

export const ConfirmationDialog = NiceModal.create(
  ({
    title,
    message,
    confirmText = "Confirm",
    cancelText = "Cancel",
    isDestructive = false,
    onConfirm,
  }: Props) => {
    const modal = useModal();
    const [isLoading, setIsLoading] = useState(false);

    // Reset loading state when dialog is hidden
    useEffect(() => {
      if (!modal.visible) {
        setIsLoading(false);
      }
    }, [modal.visible]);

    const handleClose = () => {
      if (isLoading) return;
      modal.hide();
      modal.reject();
    };

    const handleConfirm = async () => {
      try {
        setIsLoading(true);
        await onConfirm();
        modal.hide();
        modal.resolve(true);
      } catch (error) {
        setIsLoading(false);
        // Let the error be handled by the caller
        throw error;
      }
    };

    return (
      <Modal isOpen={modal.visible} onClose={handleClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"ghost"}
              mr={3}
              onClick={handleClose}
              isDisabled={isLoading}
            >
              {cancelText}
            </Button>
            <Button
              colorScheme={isDestructive ? "red" : "blue"}
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {confirmText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);
