import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  chakra,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

interface AuditLogEntry {
  id: string;
  user_id: string;
  event_type: string;
  ip_address: string;
  forwarded_ip: string | null;
  user_agent: string;
  details: Record<string, any>;
  created_at: string;
}

interface LaravelPaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: {
      url: string | null;
      label: string;
      active: boolean;
    }[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  };
}

const formatCasing = (str: string): string => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function AuditLogBlock() {
  const [expandedRowIds, setExpandedRowIds] = useState<Set<string>>(new Set());
  const [page, setPage] = useState(1);

  const toggleRow = (rowId: string) => {
    setExpandedRowIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(rowId)) {
        newIds.delete(rowId);
      } else {
        newIds.add(rowId);
      }
      return newIds;
    });
  };

  const { data: queryData, isLoading } = useQuery<
    LaravelPaginatedResponse<AuditLogEntry>
  >({
    queryKey: ["audit-log", page],
    queryFn: () =>
      axios
        .get(`user/audit-log`, {
          params: { page },
          headers: { Accept: "application/json" },
        })
        .then((res) => res.data),
  });

  return (
    <Card>
      <CardHeader>
        <VStack alignItems={"flex-start"}>
          <Heading size={"md"}>Audit Logs</Heading>
          <Text>
            See all the recent authentication events for your user account. If
            you notice any suspicious activity, please contact support and
            change your password as soon as possible. Audit logs are stored for
            up to 90 days.
          </Text>
        </VStack>
      </CardHeader>
      <CardBody pt={0}>
        {isLoading ? (
          <Center py={8}>
            <Spinner size={"lg"} color={"gray.500"} />
          </Center>
        ) : (
          <>
            <TableContainer mb={4}>
              <Table variant={"simple"}>
                <Thead>
                  <Tr>
                    <Th pl={0}>Action</Th>
                    <Th>Date</Th>
                    <Th>IP Address</Th>
                    <Th isNumeric></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {queryData
                    ? queryData.data.map((row) => (
                        <Fragment key={row.id}>
                          <Tr
                            css={{
                              "& td": {
                                borderColor: expandedRowIds.has(String(row.id))
                                  ? "transparent"
                                  : "inherit",
                              },
                            }}
                          >
                            <Td pl={0} py={2.5}>
                              {formatCasing(row.event_type)}
                            </Td>
                            <Td py={2.5}>
                              {dayjs(row.created_at).format(
                                "DD MMM YYYY h:mma"
                              )}
                            </Td>
                            <Td py={2.5}>{row.ip_address}</Td>
                            <Td py={2.5} isNumeric display={"block"}>
                              <Button
                                size={"sm"}
                                variant={"outline"}
                                colorScheme={"gray"}
                                paddingInlineEnd={2}
                                onClick={() => toggleRow(String(row.id))}
                                rightIcon={
                                  <HiChevronDown
                                    size={"18px"}
                                    style={{
                                      transform: expandedRowIds.has(
                                        String(row.id)
                                      )
                                        ? "rotate(180deg)"
                                        : "none",
                                      transition: "transform 150ms ease-out",
                                    }}
                                  />
                                }
                                sx={{
                                  "& .chakra-button__icon": {
                                    marginInlineStart: 1,
                                  },
                                }}
                              >
                                Details
                              </Button>
                            </Td>
                          </Tr>
                          <Tr
                            w={"100%"}
                            css={{
                              "& td": {
                                borderColor: expandedRowIds.has(String(row.id))
                                  ? "inherit"
                                  : "transparent",
                              },
                            }}
                          >
                            <Td colSpan={4} p={0}>
                              <chakra.div
                                pl={0}
                                width={"300px"}
                                transition={
                                  "height 150ms ease-out, opacity 100ms ease-out 50ms"
                                }
                                overflow={"hidden"}
                                style={{
                                  transitionBehavior: "allow-discrete",
                                }}
                                opacity={
                                  expandedRowIds.has(String(row.id)) ? 1 : 0
                                }
                                h={
                                  expandedRowIds.has(String(row.id))
                                    ? "calc-size(auto, size)"
                                    : "0"
                                }
                              >
                                <VStack
                                  align={"stretch"}
                                  spacing={1}
                                  pb={10}
                                  w={"300px"}
                                >
                                  <Text
                                    color={"gray.600"}
                                    fontSize={"sm"}
                                    lineHeight={"1.35"}
                                  >
                                    <Text as={"span"} fontWeight={"bold"}>
                                      User Agent:
                                    </Text>{" "}
                                    <Text
                                      as={"span"}
                                      overflow={"hidden"}
                                      wordBreak={"break-all"}
                                      css={{ textWrap: "auto" } as any}
                                    >
                                      {row.user_agent}
                                    </Text>
                                  </Text>
                                  {Object.entries(row.details).map(
                                    ([key, value]) => (
                                      <Text
                                        key={key}
                                        color={"gray.600"}
                                        fontSize={"sm"}
                                      >
                                        <Text as={"span"} fontWeight={"bold"}>
                                          {formatCasing(key)}:
                                        </Text>{" "}
                                        {typeof value === "object"
                                          ? JSON.stringify(value)
                                          : String(value)}
                                      </Text>
                                    )
                                  )}
                                </VStack>
                              </chakra.div>
                            </Td>
                          </Tr>
                        </Fragment>
                      ))
                    : null}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex
              direction={"row"}
              gap={1}
              justify={"flex-end"}
              align={"center"}
            >
              <Text fontSize={"sm"} color={"gray.600"} mr={2}>
                {queryData?.meta.from} - {queryData?.meta.to} of{" "}
                {queryData?.meta.total}
              </Text>
              <IconButton
                colorScheme={"gray"}
                disabled={page === 1}
                shadow={"0"}
                size={"sm"}
                onClick={() => setPage((p) => p - 1)}
                aria-label={"Previous page"}
              >
                <HiChevronLeft size={"18px"} />
              </IconButton>
              <IconButton
                size={"sm"}
                colorScheme={"gray"}
                shadow={"0"}
                disabled={page === queryData?.meta.last_page}
                onClick={() => setPage((p) => p + 1)}
                aria-label={"Next page"}
              >
                <HiChevronRight size={"18px"} />
              </IconButton>
            </Flex>
          </>
        )}
      </CardBody>
    </Card>
  );
}
