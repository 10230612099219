import { Box, Button, Flex, Icon, Image, VStack } from "@chakra-ui/react";
import { ReactComponent as leftArrowIcon } from "assets/icons/arrow-left-circle.svg";
import { ReactComponent as keysIcon } from "assets/icons/keys.svg";
import { ReactComponent as personIcon } from "assets/icons/person.svg";
import { ReactComponent as plugIcon } from "assets/icons/plug.svg";
import { useParams } from "hooks/use-params";
import { ReactElement, ReactNode } from "react";
import { addParamsToUrl, getSearchParamsFromUrl } from "utils/routing";
import { Link, useRoute } from "wouter";

function NavMenuButton({
  label,
  icon,
  path,
}: {
  label: ReactNode;
  icon: ReactElement;
  path: string;
}) {
  const [match] = useRoute(path);

  const fullUrl = window.location.origin + path;

  const finalDestination = addParamsToUrl(
    fullUrl,
    getSearchParamsFromUrl(window.location.href)
  );

  return (
    <Link to={finalDestination}>
      <Button
        as={"a"}
        w={"100%"}
        pl={3}
        py={6}
        fontWeight={"medium"}
        color={"brand.200"}
        justifyContent={"flex-start"}
        leftIcon={icon}
        bg={match ? "brand.400" : undefined}
      >
        {label}
      </Button>
    </Link>
  );
}

export function NavMenu() {
  const { embedded } = useParams();
  return (
    <Flex
      w={"184px"}
      bg={"brand.500"}
      left={0}
      h={"100vh"}
      top={0}
      pos={"fixed"}
      bottom={0}
      zIndex={1}
      flexShrink={0}
      p={2}
      direction={"column"}
    >
      <Image src={"/assets/rex-logo-white.svg"} h={"90px"} ml={"-0.5"} />
      <VStack spacing={2} align={"stretch"}>
        <NavMenuButton
          label={"Personal Info"}
          icon={<Icon as={personIcon} mr={2} boxSize={5} />}
          path={"/profile/personal-info"}
        />
        <NavMenuButton
          label={"Security"}
          icon={<Icon as={keysIcon} mr={2} boxSize={5} />}
          path={"/profile/security"}
        />
        <NavMenuButton
          label={"Integrations"}
          icon={<Icon as={plugIcon} mr={2} boxSize={5} />}
          path={"/profile/integrations"}
        />
      </VStack>
      <Box mt={"auto"}>
        {!embedded ? (
          <NavMenuButton
            label={"Logout"}
            icon={<Icon as={leftArrowIcon} mr={2} boxSize={5} />}
            path={"/?logout=1"}
          />
        ) : null}
      </Box>
    </Flex>
  );
}
