import {
  Button,
  Code,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

interface Props {
  token: string;
}

export const showTokenCreatedDialog = (props: Props) => {
  return NiceModal.show(TokenCreatedDialog, props);
};

export const TokenCreatedDialog = NiceModal.create(({ token }: Props) => {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
    modal.resolve();
  };

  return (
    <Modal isOpen={modal.visible} onClose={handleClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Token Created Successfully</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Text>
              You may now copy this access token and share it with your
              integrator.
            </Text>
            <Text>
              Once this dialog is closed,{" "}
              <Text fontWeight={"bold"} as={"span"}>
                you will not be able to see this token again.
              </Text>
            </Text>
            <Code p={4} borderRadius={"md"} wordBreak={"break-all"}>
              {token}
            </Code>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
