import { Box, Text } from "@chakra-ui/react";
import {
  chakraComponents,
  Select as ChakraReactSelect,
  createFilter,
  GroupBase,
  Props,
} from "chakra-react-select";
import { forwardRef } from "react";

const MAX_RENDERED_OPTIONS = 100;

const MenuList = ({ children, ...props }: any) => {
  const hiddenCount = Array.isArray(children)
    ? children.slice(MAX_RENDERED_OPTIONS).length
    : 0;
  return (
    <chakraComponents.MenuList {...props}>
      {
        Array.isArray(children)
          ? children.slice(0, MAX_RENDERED_OPTIONS) /* Options */
          : children /* NoOptionsLabel */
      }
      {hiddenCount ? (
        <Box py={"1"} px={"13px"}>
          <Text color={"gray.500"}>+ {hiddenCount} more</Text>
        </Box>
      ) : null}
    </chakraComponents.MenuList>
  );
};

export const Select = forwardRef(function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>, ref: any) {
  return (
    <ChakraReactSelect<Option, IsMulti, Group>
      filterOption={createFilter({ ignoreAccents: false })}
      {...props}
      components={{ MenuList, ...props.components }}
      ref={ref}
    />
  );
});
